<template>
  <div>
    <b-card class="p-0 mt-4">
      <b-row class="mb-2">
        <b-col md="4">
          <!-- <h4 class="card-title">Admin</h4> -->
          <!-- <strong>TOTAL DATA {{totalData}}</strong> -->
        </b-col>
        <b-col md="8" class="text-right">
          <b-button
            v-b-popover.top.hover="'Tambah Tanda Tangan'"
            size="sm"
            href="#/signatures/create"
            variant="primary"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col md="12">
          <b-form inline @submit.prevent="doSearch">
            <b-input
              placeholder="Cari nama"
              class="mr-2"
              v-model="model.name"
            />
            <!-- <b-input placeholder="Cari email" v-model="model.email" /> -->
            <b-button type="submit" class="ml-2" variant="secondary"
              >Cari</b-button
            >
          </b-form>
        </b-col>
      </b-row>
      <paginated-table
        ref="dataTable"
        :can-delete="$store.getters['account/accountData'].role === 'super admin'"
        :can-show="false"
        :can-edit="true"
        :can-approve="false"
        :can-reject="false"
        :data-url="apiUrl.signature"
        :data-columns="fields"
        :data-params="['name', 'email']"
        v-on:sendTotalData="setTotalData"
      >
      </paginated-table>
    </b-card>
  </div>
</template>
<script>
import PaginatedTable from "@/components/PaginatedTable";
export default {
  components: {
    PaginatedTable,
  },
  data() {
    return {
      apiUrl: {
        signature: process.env.VUE_APP_API_URL + "signatures",
      },
      totalData: 0,
      model: {
        name: this.$route.query.name,
        email: this.$route.query.email,
        role: "admin",
      },
      items: [],
      fields: [
        {
          key: "name",
          label: "Nama",
        },
        {
          key: "regency",
          label: "Kota/Kabupaten",
        },
        {
          key: "signature_path",
          label: "Tanda Tangan"
        },
        {
          key: "actions",
          label: "",
          thClass: "actions",
          class: "text-right",
        },
      ],
    };
  },
  methods: {
    doSearch() {
      this.$refs.dataTable.doFilter(this.model);
    },
    setTotalData (total) {
      this.totalData = total
    },
    getUrlParams() {
      this.model.name = this.model.name !== undefined ? this.model.name : null;
      this.model.email =
        this.model.email !== undefined ? this.model.email : null;
    },
    clearField() {
      this.model.name = null;
      this.model.email = null;
    },
  },
};
</script>