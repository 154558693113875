var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.model.id !== null)?_c('b-breadcrumb',{attrs:{"items":[
      {
        text: 'Daftar Tanda Tangan',
        href: '#/users',
      },
      {
        text: 'Ubah Tanda Tangan',
        active: true,
      } ]}}):_c('b-breadcrumb',{attrs:{"items":[
      {
        text: 'Daftar Tanda Tangan',
        href: '/#/users',
      },
      {
        text: 'Tambah Tanda Tangan',
        active: true,
      } ]}}),_c('b-card',{staticStyle:{"max-width":"500px","margin":"auto"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-group',{staticClass:"capitalize",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{attrs:{"for":""}},[_vm._v("Nama Ketua PD "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Ketikkan Nama Ketua PD","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('b-form-group',{staticClass:"capitalize",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',{attrs:{"for":""}},[_vm._v("Kota/Kabupaten "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('treeselect',{attrs:{"multiple":false,"placeholder":"Pilih Kota/Kabupaten","options":_vm.options.regencies},model:{value:(_vm.model.regency_id),callback:function ($$v) {_vm.$set(_vm.model, "regency_id", $$v)},expression:"model.regency_id"}})],1),_c('b-form-group',{staticClass:"capitalize",attrs:{"id":"input-group-4","label":"","label-for":"input-4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Upload Tanda Tangan "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-file',{attrs:{"id":"input-4","type":"number","placeholder":"Ketikkan Nomor Kontak"},model:{value:(_vm.model.file_signature),callback:function ($$v) {_vm.$set(_vm.model, "file_signature", $$v)},expression:"model.file_signature"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(" Simpan")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }