<template>
  <div>
    <b-breadcrumb
      v-if="model.id !== null"
      :items="[
        {
          text: 'Daftar Tanda Tangan',
          href: '#/users',
        },
        {
          text: 'Ubah Tanda Tangan',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-breadcrumb
      v-else
      :items="[
        {
          text: 'Daftar Tanda Tangan',
          href: '/#/users',
        },
        {
          text: 'Tambah Tanda Tangan',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-card
      style="max-width: 500px; margin: auto"
    >
      <b-form @submit.prevent="onSubmit">
        <b-form-group class="capitalize" id="input-group-1" label-for="input-1">
          <label for="">Nama Ketua PD <sup class="text-danger">*</sup></label>
          <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan Nama Ketua PD"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="capitalize"
          id="input-group-1"
          label-for="input-1"
        >
          <label for="">Kota/Kabupaten <sup class="text-danger">*</sup></label>
          <treeselect
            v-model="model.regency_id"
            :multiple="false"
            placeholder="Pilih Kota/Kabupaten"
            :options="options.regencies"
          />
        </b-form-group>
        <b-form-group class="capitalize" id="input-group-4" label="" label-for="input-4">
          <label for="">Upload Tanda Tangan <sup class="text-danger">*</sup></label>
          <!-- <a class="btn" @click="toggleShow">set avatar</a> -->
          <!-- <my-upload field="img"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
            :value.sync="show"
            :langExt="en"
            :width="300"
            :height="300"
            url="/upload"
            :params="params"
            :headers="headers"
            img-format="png"></my-upload>
          <img :src="imgDataUrl"> -->
          <b-form-file
            id="input-4"
            v-model="model.file_signature"
            type="number"
            placeholder="Ketikkan Nomor Kontak"
          ></b-form-file>
        </b-form-group> 
        <b-button type="submit" variant="primary" :disabled="isLoading"
          ><font-awesome-icon v-if="isLoading" icon="spinner" spin />
          Simpan</b-button
        >
      </b-form>
    </b-card>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import 'babel-polyfill'; // es6 shim
import myUpload from 'vue-image-crop-upload';
export default {
  components: { 
    Treeselect,
    'my-upload': myUpload
  },
  data() {
    return {
      isLoading: false,
      apiUrl: {
        signature: process.env.VUE_APP_API_URL + "signatures",
        regency: process.env.VUE_APP_API_URL + "regencies",
      },
      modelType: {
        password: "password",
      },
      options: {
        regencies: [],
      },
      model: {
        id: null,
        regency_id: null,
        name: null,
        file_signature: null
      },
      show: false,
			params: {
				token: '123456798',
				name: 'avatar'
			},
			headers: {
				smail: '*_~'
			},
			imgDataUrl: '', // the datebase64 url of created image
      en: {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Image only',
          outOfSize: 'Image exceeds size limit: ',
          lowestPx: 'Image\'s size is too low. Expected at least: '
        }
      }
    };
  },
  watch: {
    "$route.params.id": function (val) {
      if (val !== undefined) {
        this.getDetail();
      } else {
        this.resetForm();
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.getDetail();
    }
    this.getRegency();
  },
  methods: {
    resetForm() {
      this.model.id = null;
      this.model.name = null;
      this.model.username = null;
      this.model.email = null;
      this.model.whatsapp_number = null;
      this.model.generate_password = 0;
    },
    onSubmit() {
      this.isLoading = true;
        const formData = new FormData()
      if (this.model.id !== null && this.model.id !== '') {
        formData.append('_method', 'PATCH');
      }
      formData.append('regency_id', this.model.regency_id)
      formData.append('name', this.model.name)
      if (this.model.file_signature !== null && this.model.file_signature !== '') {
        formData.append('file_signature', this.model.file_signature)
      }
      this.$http.post( this.apiUrl.signature + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData )
      .then((response) => {
        this.isLoading = false;
        if (this.model.id !== null && this.model.id !== "") {
          this.$store.dispatch(
            "notification/success",
            "Tanda Tangan berhasil diubah."
          );
        } else {
          this.$store.dispatch(
            "notification/success",
            "Tanda Tangan berhasil disimpan."
          );
        }
        this.$router.push("/signatures");
      })
      .catch((error) => {
        this.isLoading = false;
        this.$store.dispatch("notification/error", error);
      });
    },
    getRegency() {
      this.isLoading = true;
      this.$http
        .get(
          this.apiUrl.regency +
            "?limit=1000&province_id=950b9d3a-c996-4207-97e7-5a2858981979"
        )
        .then((response) => {
          this.options.regencies = [];
          response.data.data.forEach((data) => {
            this.options.regencies.push({
              id: data.id,
              label: data.name,
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getDetail() {
      this.isLoading = true;
      this.$http
        .get(this.apiUrl.signature + "/" + this.$route.params.id)
        .then((response) => {
          this.model.id = response.data.id
          this.model.regency_id = response.data.regency_id
          this.model.name = response.data.name
          this.model.signature_path = response.data.signature_path
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    toggleShow() {
      this.show = !this.show;
    },
          /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field){
      console.log('-------- crop success --------');
      this.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field){
      console.log('-------- upload success --------');
      console.log(jsonData);
      console.log('field: ' + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field){
      console.log('-------- upload fail --------');
      console.log(status);
      console.log('field: ' + field);
    }
  },
  
};
</script>